import Layout from "@components/Layout";
import SEO from "@components/SEO";
import metaData from "@data/metaData.json";
import HeroSection from "@components/HeroSection";
import data from "@data/MarketingServices.json";
import { css } from "@emotion/react";
import colors from "@variables/colors";
import { mdLayout } from "@variables/layout";
import ServiceSection from "@components/services/ServiceSection";

const MarketingServicesPage = () => {
  return (
    <Layout>
      <SEO
        title={metaData.marketingServices.title}
        description={metaData.marketingServices.description}
        {...metaData.marketingServices}
      />

      <HeroSection
        css={hero_section_style}
        description={data.heroSection.description}
        title={data.heroSection.title}
        imgAlt={data.heroSection.imageTitle}
        imgSrc={data.heroSection.image}
      />

      {data.services.map((service, index) => (
        <ServiceSection
          data={service}
          data-direction={index % 2 == 0 ? "row-reverse" : ""}
        />
      ))}
    </Layout>
  );
};

export default MarketingServicesPage;

const hero_section_style = css`
  background: ${colors.mintGreen[200]};
  .hero-section__row {
    flex-direction: row-reverse;
    @media ${mdLayout} {
      flex-direction: column-reverse;
    }
  }
  .hero-section__content {
    width: 60%;
  }
  .hero-section__description {
    max-width: 54ch;
  }
  .hero-section__image {
    width: 30%;
  }
`;
